// Make sure the global stylesheet is added to the gatsby build.
import { WIDGET_DESTINATION_PATHS } from './src/constants';
import './src/styles/import-tailwind-css';
import appInsights from './src/utils/applicationinsights';
import { getDestinationPath } from './src/utils/getDestinationPath';
import { waitForDonateWidget } from './src/utils/waitForDonateWidget';
import { getDailyClubBalanceReport } from './src/utils/getDailyClubBalanceReport';
import { getClubInvoiceReport } from './src/utils/getClubInvoiceReport';
import { FEATURE_INVOICE, isEnabled } from './src/utils/features';

// Export the central `wrapRootElement` function.
export { wrapRootElement } from './src/utils/wrap-root-element';

// Init and track page view in Azure App Insights.
// if (appInsights.config.instrumentationKey) {
appInsights.loadAppInsights();
appInsights.trackPageView();
// }

const scrollTo = id => {
  // eslint-disable-next-line consistent-return
  const intervalId = setInterval(() => {
    const el = document.querySelector(id);
    if (el) {
      clearInterval(intervalId);
      return window.scrollTo(0, el.getBoundingClientRect().top);
    }
  }, 500);
};

export const onRouteUpdate = ({
  location: { hash, pathname },
  prevLocation,
}) => {
  if (typeof window !== 'undefined') {
    window.dispatchEvent(new CustomEvent('gatsby-route-update'));

    if (pathname.includes('daily-club-balance-report')) {
      const isInvoiceEnabled = isEnabled(FEATURE_INVOICE);
      if (isInvoiceEnabled) {
        const pathSegments = pathname.split('/').filter(segment => segment);
        const basePathIndex = pathSegments.indexOf('daily-club-balance-report');

        if (
          basePathIndex !== -1 &&
          pathSegments.length - basePathIndex - 1 === 1
        ) {
          const clubId = pathSegments[basePathIndex + 1];
          getDailyClubBalanceReport(clubId);
          return;
        }
      }
    }

    if (pathname.includes('club-invoice-report')) {
      const isInvoiceEnabled = isEnabled(FEATURE_INVOICE);
      if (isInvoiceEnabled) {
        const pathSegments = pathname.split('/').filter(segment => segment);
        const basePathIndex = pathSegments.indexOf('club-invoice-report');

        if (
          basePathIndex !== -1 &&
          pathSegments.length - basePathIndex - 1 === 1
        ) {
          const clubId = pathSegments[basePathIndex + 1];
          getClubInvoiceReport(clubId);
          return;
        }
      }
    }

    if (hash && pathname !== prevLocation?.pathname) scrollTo(hash);

    // Load donateWidget on browser navigating
    const { isDesiredPath: isWidgetDestination } = getDestinationPath(
      WIDGET_DESTINATION_PATHS
    );

    if (isWidgetDestination) {
      waitForDonateWidget('#wrapper-id', 500, 5000);
    }
  }
};
